import { EntityId } from "@reduxjs/toolkit"
import { atomFamily, useRecoilState } from "recoil"

const openGraphImageAtom = atomFamily({
  key: "openGraphImageAtom",
  default: {
    url: "",
    width: 0,
    height: 0,
  },
})

export const useOpenGraphImage = (pageId: EntityId) => {
  return useRecoilState(openGraphImageAtom(pageId))
}
