import { EntityId } from "@reduxjs/toolkit"
import { atomFamily, useRecoilState } from "recoil"

const metaDescriptionAtom = atomFamily({
  key: "metaDescriptionAtom",
  default: "",
})

export const useMetaDescription = (pageId: EntityId) => {
  return useRecoilState(metaDescriptionAtom(pageId))
}
