import { EntityId } from "@reduxjs/toolkit"
import { atomFamily, useRecoilState } from "recoil"

const metaTitleAtom = atomFamily({
  key: "metaTitleAtom",
  default: "",
})

export const useMetaTitle = (pageId: EntityId) => {
  return useRecoilState(metaTitleAtom(pageId))
}
