import { EntityId } from "@reduxjs/toolkit"
import { atomFamily, useRecoilState } from "recoil"

const canonical = atomFamily({
  key: "canonical",
  default: "",
})

export const useCanonical = (pageId: EntityId) => {
  return useRecoilState(canonical(pageId))
}
